import { UnleashClient } from 'unleash-proxy-client';
import { Device, FlatGroup } from '@automata/api/apiSchemas';

export type Notify = (message: string, type: 'success' | 'error') => void;

export interface ContextI {
  currentWorkspace: {
    id: string;
    name: string;
    organizationId: string;
    slug: string;
  };
  notify: Notify;
  unleash: UnleashClient;
}

export type ModelType = Device | FlatGroup;

export enum ModelNames {
  WORKFLOW = 'workflow',
  DEVICE = 'device',
  DEVICESTATE = 'device_state',
  GROUP = 'group',
}

export const UIModelNames = {
  ...ModelNames,
  USER: 'user',
};
