import { TextField } from '@automata/ui';
import MuiAutocomplete from '@mui/material/Autocomplete';
import { MaestroVersion } from '@automata/api/apiSchemas';
import { getVersionCompatibleName } from './utils';
import { ControllerRenderProps } from 'react-hook-form';
import { MaestroEditorContent } from 'components/MaestroEditor';

export type Props = {
  versions: MaestroVersion[];
  reactHookFormField: ControllerRenderProps<
    MaestroEditorContent,
    `workflow.resource_versions.maestro`
  >;
  disabled: boolean;
  setCompatibleDriversVersionFilter: (value: string) => void;
};

export const CustomVersionSelector = ({
  versions,
  reactHookFormField,
  disabled,
  setCompatibleDriversVersionFilter,
}: Props) => {
  const handleChange = (value: string) => {
    const selectedVersion = versions.find((version) => version.name === value);
    setCompatibleDriversVersionFilter(selectedVersion?.drivers_version || 'v*');
    reactHookFormField.onChange(value);
  };

  return (
    <MuiAutocomplete<string, false, true, true>
      id="maestro-version-selector-combo"
      // Fixing the height is necessary to align with the other inputs
      sx={{ width: '10rem', height: '70px' }}
      disabled={disabled}
      freeSolo
      disableClearable
      value={reactHookFormField.value}
      options={versions.map((version) => getVersionCompatibleName(version))}
      renderInput={(params) => (
        <TextField
          {...params}
          {...reactHookFormField}
          label="Maestro version"
          error={!reactHookFormField.value}
          helperText={reactHookFormField.value ? '' : 'Required'}
        />
      )}
      onChange={(_, value) => handleChange(value)}
    />
  );
};
