import Box from '@mui/material/Box';
import { WorkflowForm } from 'components/WorkflowForm';
import { ResourceActions } from 'components/ResourceActions';
import { ModelNames } from 'model/types';
import { GroupForm } from 'components/GroupForm';
import { CommonProps } from './types';
import { RoutedGroup } from 'screens/DevicesScreen/DevicesList/types';
import { Typography } from '@automata/ui';

export const Form = ({
  resource,
  resourceID,
  resourceType,
  resourceAction,
  onClose,
  requestAuthorisation,
}: CommonProps) => {
  const forms = {
    [ModelNames.WORKFLOW]: (
      <WorkflowForm
        workflowID={resourceID}
        mode={resourceAction}
        onClose={onClose}
        requestAuthorisation={requestAuthorisation}
      />
    ),
    [ModelNames.GROUP]: (
      <GroupForm
        group={resource as RoutedGroup}
        mode={resourceAction}
        onClose={onClose}
      />
    ),
  };

  return (
    <>
      {resourceAction !== ResourceActions.CREATE && (
        <Typography variant="body1">
          {`Are you sure you want to ${resourceAction.toLowerCase()} this ${resourceType}?`}
        </Typography>
      )}
      <Box marginTop={1}>{forms[resourceType]}</Box>
    </>
  );
};
