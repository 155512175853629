import { useCallback, useState } from 'react';
import { mutatePathAsUrl } from 'utils/url';
import { isRequestIntentionApiError } from 'utils/error';
import { Action, RequestIntentionResponse } from '@automata/api/apiSchemas';
import { useRouter } from 'next/router';
import { useRequestIntention } from '@automata/api/apiComponents';
import { ResourceActions } from 'components/ResourceActions';
import { ModelNames } from 'model/types';
import { getErrorMessage } from '@automata/utils';
import useUserV2 from './useUserV2';

export interface UseComplianceReauthenticate {
  reauthenticate: (
    resourceAction: ResourceActions,
    resourceType: ModelNames,
    resourceId?: string
  ) => Promise<void>;
  loading: boolean;
  error?: string;
}

export const useComplianceReauthenticate = (): UseComplianceReauthenticate => {
  const { mutateAsync: requestIntention } = useRequestIntention({});
  const { data: user } = useUserV2();
  const router = useRouter();

  const [error, setError] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);

  const reauthenticate = useCallback(
    (
      resourceAction: ResourceActions,
      resourceType: ModelNames,
      resourceId?: string
    ) => {
      setLoading(true);

      return (async () => {
        let data: RequestIntentionResponse | undefined;
        try {
          data = await requestIntention({
            body: {
              action: resourceAction.toLowerCase() as Action,
              scope: resourceType,
            },
          });
        } catch (e) {
          let msg = 'unknown error';

          if (isRequestIntentionApiError(e)) {
            switch (e.status) {
              case 401:
                msg = `Unauthenticated: ${e.payload.message}`;
                break;
              case 404:
                msg = 'Request intention API not found';
                break;
              case 500:
              default:
                msg = `Unknown error: ${getErrorMessage(e.payload, 'unknown')}`;
            }
          } else {
            msg = getErrorMessage(e, 'unknown error');
          }

          setError(msg);
          setLoading(false);
          return;
        }

        if (!data?.requestIntention) {
          setError('Missing payload from API response');
          setLoading(false);
          return;
        }

        const redirectPath = mutatePathAsUrl(router.asPath, (url) => {
          url.searchParams.set('action', resourceAction);

          if (resourceId) {
            url.searchParams.set('id', resourceId);
          }

          return url;
        });

        await user?.reauthenticate({
          requestIntention: data.requestIntention,
          redirectPath,
        });

        setLoading(false);
      })();
    },
    [requestIntention, router.asPath, user]
  );

  return {
    reauthenticate,
    loading,
    error,
  };
};
