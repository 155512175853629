import { useCallback } from 'react';
import Divider from '@mui/material/Divider';
import Box from '@mui/system/Box';
import Stack from '@mui/system/Stack';
import { useForm } from 'react-hook-form';
import { WorkflowDetailed } from '@automata/api/apiSchemas';
import { schemas } from '@automata/api/zod';
import {
  UpdateWorkflowRequestBody,
  useGetWorkflowDetails,
  useUpdateWorkflow,
} from '@automata/api/apiComponents';
import { useSnackbar } from 'hooks/useSnackbar';
import { useFlag } from 'hooks/unleashHooks';
import { Placeholder } from 'components/Placeholder';
import { AsyncStatus } from 'components/AsyncStatus';
import { TextField, TrackedButton, Typography } from '@automata/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { env, getErrorMessage } from '@automata/utils';

interface FormWrapperProps {
  workflow: WorkflowDetailed;
  refetchData: () => Promise<void>;
  onClose: () => void;
  requestAuthorisation?: string;
}

const FormWrapper = ({
  workflow,
  refetchData,
  onClose,
  requestAuthorisation,
}: FormWrapperProps): JSX.Element => {
  const reasonIsEnabled = useFlag('fe.reason');
  const {
    mutateAsync: updateWorkflow,
    isLoading: isUpdating,
    error,
  } = useUpdateWorkflow({
    onSuccess: refetchData,
  });

  const { enqueueSnackbar } = useSnackbar();

  const defaultValues: UpdateWorkflowRequestBody = {
    reason: {
      details: reasonIsEnabled ? '' : env('NEXT_PUBLIC_REASON_DEFAULT_STRING'),
    },
    payload: {
      name: `${workflow.name}`,
      definition: workflow.definition,
    },
  };

  const { handleSubmit, register, formState } =
    useForm<UpdateWorkflowRequestBody>({
      defaultValues: defaultValues,
      resolver: zodResolver(schemas.updateWorkflow_Body),
    });

  const onSubmit = useCallback(
    async (formData: UpdateWorkflowRequestBody) => {
      try {
        await updateWorkflow({
          body: formData,
          pathParams: { workflowID: workflow.id },
          headers: { 'X-Request-Authorization': requestAuthorisation },
        });
        enqueueSnackbar('Workflow renamed', { variant: 'success' });
        onClose();
      } catch (err: unknown) {
        const error = getErrorMessage(err, 'unknown error');
        enqueueSnackbar(`Cannot rename workflow: ${error}`, {
          variant: 'error',
        });
      }
    },
    [
      enqueueSnackbar,
      onClose,
      updateWorkflow,
      workflow.id,
      requestAuthorisation,
    ]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <Box>
          <Stack spacing={2}>
            <TextField
              margin="dense"
              label="Workflow Name"
              fullWidth
              {...register('payload.name')}
              data-cy="wflow-form-name"
            />
            {reasonIsEnabled && (
              <>
                <Divider />
                <Typography>
                  You need to add a reason for this change
                </Typography>
                <TextField
                  margin="dense"
                  multiline
                  placeholder="Reason"
                  fullWidth
                  {...register('reason.details')}
                />
              </>
            )}
          </Stack>
        </Box>
        <Box>{error !== null && <AsyncStatus error={error as any} />}</Box>
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <TrackedButton
            trackLabel="form-cancel-rename"
            disabled={isUpdating}
            color="secondary"
            variant="text"
            onClick={onClose}
          >
            Cancel
          </TrackedButton>
          <TrackedButton
            trackLabel="form-submit-rename"
            loading={isUpdating}
            disabled={!formState.isValid}
            variant="contained"
            type="submit"
          >
            Rename
          </TrackedButton>
        </Stack>
      </Stack>
    </form>
  );
};

interface Props {
  onClose: () => void;
  refetchData: () => Promise<void>;
  workflowID: string;
  requestAuthorisation?: string;
}

export const RenameWorkflowForm = ({
  workflowID,
  onClose,
  refetchData,
  requestAuthorisation,
}: Props): JSX.Element | null => {
  const {
    data: workflowData,
    isLoading,
    isLoadingError,
  } = useGetWorkflowDetails({
    pathParams: {
      workflowID: workflowID,
    },
  });

  if (isLoading === true) {
    return <Placeholder copy="Getting data..." />;
  }

  if (workflowData === undefined && isLoadingError === true) {
    return <Placeholder copy="Error loading workflow" />;
  }

  return (
    <FormWrapper
      workflow={workflowData}
      refetchData={refetchData}
      onClose={onClose}
      requestAuthorisation={requestAuthorisation}
    />
  );
};
