interface WorkflowFile {
  path: string;
  content: string;
}

export class MultiFileWorkflow {
  workflow: string | null = null;

  includes: WorkflowFile[] = [];

  addFile(path: string, content: string): void {
    if (!path.endsWith('.py')) {
      return;
    }

    if (content.includes('AutomataProtocol')) {
      this.workflow = content;
    } else {
      this.includes.push({ path, content });
    }

    if (this.workflow !== null) {
      const { workflow } = this;
      this.includes.forEach((include: WorkflowFile) => {
        const importPath = include.path.replace('.py', '').split('/').join('.');

        const expression = `from ${importPath} import [a-zA-Z0-9, ()]*`;
        this.workflow = workflow.replace(
          new RegExp(expression),
          include.content
        );
      });
    }
  }

  getWorkflow(): string | null {
    return this.workflow;
  }
}
