import { RequestIntentionError } from '@automata/api/apiComponents';
import type { Error as SchemaError } from '@automata/api/apiSchemas';

export const isRequestIntentionApiError = (
  obj: unknown
): obj is RequestIntentionError => {
  const err = obj as RequestIntentionError;
  const payload = err.payload as SchemaError;

  return (
    err.payload !== undefined &&
    typeof payload.id == 'string' &&
    typeof payload.message == 'string' &&
    Number.isInteger(err.status)
  );
};
