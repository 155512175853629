import { Select, TextField } from '@automata/ui';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import { Control, FieldValues, Path, useController } from 'react-hook-form';

interface DriversSelectorProps<T extends FieldValues> {
  name: Path<T>;
  archetypesVersions: string[];
  control: Control<T, undefined>;
  small?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  compatibilityFilter?: (version: string) => boolean;
}

const customVersion = 'custom';

export const DriversSelector = <T extends FieldValues>({
  archetypesVersions,
  control,
  name,
  small,
  disabled = false,
  fullWidth = true,
  compatibilityFilter,
}: DriversSelectorProps<T>): JSX.Element => {
  const { field } = useController({
    name,
    control,
  });
  let selectValue: string = field.value;
  let inputValue = null;
  if (!selectValue) {
    selectValue = archetypesVersions[0]; // assuming they're in desc version order
  } else if (!archetypesVersions.includes(selectValue)) {
    selectValue = customVersion;
    inputValue = field.value === customVersion ? '' : field.value;
  }

  const size = small ? 'small' : 'medium';

  return (
    <FormControl fullWidth={fullWidth} size={size}>
      <Stack direction={fullWidth ? 'column' : 'row'} gap={2}>
        <Select
          label="Drivers version"
          name={field.name}
          disabled={disabled}
          onChange={field.onChange}
          onBlur={field.onBlur}
          value={selectValue}
          MenuProps={{ sx: { height: '50vh' } }}
          error={!field.value}
          helperText={field.value ? '' : 'Required'}
        >
          {archetypesVersions.map((version) => {
            return (
              <MenuItem key={version} value={version}>
                {version}
                {compatibilityFilter &&
                  !compatibilityFilter(version) &&
                  ' (incompatible)'}
              </MenuItem>
            );
          })}
          <MenuItem value={customVersion}>Enter manually</MenuItem>
        </Select>
        {inputValue !== null ? (
          <TextField
            fullWidth={fullWidth}
            placeholder="latest"
            name={field.name}
            disabled={disabled}
            onChange={field.onChange}
            onBlur={field.onBlur}
            value={inputValue}
            label="Enter driver version here"
          />
        ) : null}
      </Stack>
    </FormControl>
  );
};
