import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import WarningIcon from '@mui/icons-material/Warning';
import { Typography } from '@automata/ui';

const styles = {
  card: { backgroundColor: 'grey.200' },
};

interface Props {
  message: string;
}

export const WarningCard = ({ message }: Props): JSX.Element => {
  return (
    <Card>
      <CardContent sx={styles.card}>
        <Stack direction="row" spacing={1} alignItems="center">
          <WarningIcon color="warning" fontSize="small" />
          <Typography>{message}</Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

WarningCard.displayName = 'WarningCard';
