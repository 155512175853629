import {
  ComplianceConfig,
  ComplianceDeviceStateConfig,
  ComplianceResourceConfig,
} from '@automata/api/apiSchemas';
import { ModelNames } from './types';
import { ResourceActions } from 'components/ResourceActions';

const modelNamesToCompliance: Record<ModelNames, keyof ComplianceConfig> = {
  [ModelNames.DEVICE]: 'device',
  [ModelNames.GROUP]: 'group',
  [ModelNames.WORKFLOW]: 'workflow',
  [ModelNames.DEVICESTATE]: 'device_state',
};

const resourceActionsToComplianceResourceConfig: Record<
  ResourceActions,
  keyof ComplianceResourceConfig | keyof ComplianceDeviceStateConfig
> = {
  // resource config
  [ResourceActions.CREATE]: 'create',
  [ResourceActions.DELETE]: 'delete',
  [ResourceActions.DUPLICATE]: 'duplicate',
  [ResourceActions.EDIT]: 'edit',
  [ResourceActions.RENAME]: 'rename',
  // device states
  [ResourceActions.START]: 'start',
  [ResourceActions.PAUSE]: 'pause',
  [ResourceActions.RESUME]: 'resume',
  [ResourceActions.STOP]: 'stop',
  [ResourceActions.RESET]: 'reset',
  [ResourceActions.LOAD]: 'load',
  [ResourceActions.CONFIGURE]: 'configure',
};

export const resourceActionIsCompliaceDeviceStateConfig = (
  resourceAction: string
): resourceAction is keyof ComplianceDeviceStateConfig => {
  return false;
};

export const resoureTypeToComplianceName = (
  modelName: ModelNames
): keyof ComplianceConfig => {
  return modelNamesToCompliance[modelName];
};

export const resourceActionToComplianceAction = (
  resourceAction: ResourceActions
): keyof ComplianceResourceConfig | keyof ComplianceDeviceStateConfig => {
  return resourceActionsToComplianceResourceConfig[resourceAction];
};
