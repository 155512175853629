import { WorkflowGit } from '@automata/api/apiSchemas';

export const initialGitWorkflow: WorkflowGit = {
  revision: {
    kind: 'commit',
    ref: '<>',
  },
  folder: '<>',
  resource_versions: {},
  ssh_private_key_pem: '<>',
  ssh_uri: '<>',
};
