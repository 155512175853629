import { ReAuthenticateModal } from 'components/Modals/ReAuthenticate';
import { ReactElement, ReactNode } from 'react';
import { useCompliance } from 'hooks/useCompliance';
import { useComplianceReauthenticate } from 'hooks/useComplianceReauthenticate';
import {
  resourceActionToComplianceAction,
  resoureTypeToComplianceName,
} from 'model/Compliance';
import { CommonProps } from './types';

export type Props = CommonProps & {
  children: ReactNode;
};

export const AuthenticationChecker = ({
  resourceType,
  requestAuthorisation,
  onClose,
  resourceAction,
  resourceID,
  open,
  children,
}: Props): ReactElement | null => {
  const { requiresSigning } = useCompliance();
  const { reauthenticate, loading, error } = useComplianceReauthenticate();

  if (
    !requestAuthorisation &&
    requiresSigning(
      resoureTypeToComplianceName(resourceType),
      resourceActionToComplianceAction(resourceAction)
    )
  ) {
    return (
      <ReAuthenticateModal
        title={`${resourceAction} ${resourceType} authorisation`}
        open={open}
        loading={loading}
        error={error}
        onClose={onClose}
        onSubmit={() =>
          reauthenticate(resourceAction, resourceType, resourceID)
        }
      />
    );
  }

  return <>{children}</>;
};
