import { useEffect, useState, useCallback, useRef } from 'react';
import { MultiFileWorkflow } from 'model/MultiFileWorkflow';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { TrackedButton } from '@automata/ui';
import { EditorProps } from 'model/WorkflowTypes';
import { useSnackbar } from 'hooks/useSnackbar';
import { CodeEditor } from 'components/CodeEditor/CodeEditorDynamic';

const directoriesToIgnore = ['.mypy_cache'];

export const TextEditor = ({
  requestData,
  updateData,
  onContentUpdated,
}: EditorProps): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const [readOnly, setReadOnly] = useState(true);
  const [content, setContent] = useState<string | null>(null);
  const refContainer = useRef<HTMLElement>(null);

  const processFiles = useCallback(
    (result: MultiFileWorkflow, item: FileSystemEntry) => {
      if (directoriesToIgnore.includes(item.name)) {
        return;
      }

      if (item.isDirectory) {
        (item as FileSystemDirectoryEntry)
          .createReader()
          .readEntries((entries: FileSystemEntry[]) => {
            entries.forEach((entry: FileSystemEntry) => {
              processFiles(result, entry);
            });
          });
      } else {
        (item as FileSystemFileEntry).file((file: File) => {
          const reader = new FileReader();
          reader.onload = () => {
            const fileContent = reader.result as string;
            result.addFile(item.fullPath, fileContent);
            const workflow = result.getWorkflow();
            if (workflow !== null) {
              setContent(workflow);
            }
          };

          reader.readAsText(file);
        });
      }
    },
    [setContent]
  );

  const dragOver = (event: DragEvent) => {
    event.preventDefault();
  };

  const drop = useCallback(
    (event: DragEvent) => {
      event.preventDefault();
      if (event.dataTransfer !== null) {
        // Auto enable saving as dropping is supposed to be used for rapid test cycle
        setReadOnly(false);

        const result = new MultiFileWorkflow();
        Object.values(event.dataTransfer.items).forEach(
          (item: DataTransferItem) => {
            if (typeof item.webkitGetAsEntry === 'function') {
              const entry = item.webkitGetAsEntry();
              if (entry !== null) {
                processFiles(result, entry);
              }
            }
          }
        );
      }
    },
    [processFiles, setReadOnly]
  );

  useEffect(() => {
    const dropArea = refContainer.current;
    dropArea?.addEventListener('dragover', dragOver, false);
    dropArea?.addEventListener('drop', drop, false);

    return () => {
      dropArea?.removeEventListener('dragover', dragOver);
      dropArea?.removeEventListener('drop', drop);
    };
  }, [drop, refContainer]);

  useEffect(() => {
    if (content === null && requestData !== undefined) {
      const newContent = requestData.definition.python ?? '';
      setContent(newContent);
    }
  }, [content, requestData]);

  const makeUpdate = useCallback(async () => {
    try {
      const workflow = await updateData({ python: content ?? undefined });
      onContentUpdated(workflow);
      enqueueSnackbar('Workflow updated', {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar('Workflow failed to update', {
        variant: 'error',
      });
    }
  }, [content, enqueueSnackbar, onContentUpdated, updateData]);

  return (
    <Box width="100%" ref={refContainer}>
      <CodeEditor
        mode="python"
        height="500px"
        width="100%"
        value={content ?? ''}
        readOnly={readOnly}
        onChange={setContent}
      />
      <Box paddingTop={1}>
        <FormControl component="fieldset">
          <FormControlLabel
            value="ReadOnly"
            control={
              <Switch
                checked={readOnly}
                onChange={() => {
                  return setReadOnly(!readOnly);
                }}
                color="primary"
              />
            }
            label="ReadOnly"
            labelPlacement="start"
          />
        </FormControl>
        {!readOnly && (
          <TrackedButton
            variant="contained"
            trackLabel="wflow-save"
            onClick={makeUpdate}
          >
            Save
          </TrackedButton>
        )}
      </Box>
    </Box>
  );
};
