import { ResourceActions } from 'components/ResourceActions';
import { CreateWorkflowForm } from './Create';
import { DuplicateWorkflowForm } from './Duplicate';
import { RenameWorkflowForm } from './Rename';
import { DeleteWorkflowForm } from './Delete';
import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';

export interface WorkflowFormProps {
  workflowID?: string;
  mode: Omit<ResourceActions, ResourceActions.DELETE>;
  onClose: () => void;
  requestAuthorisation?: string;
}

export const WorkflowForm = ({
  workflowID,
  mode,
  onClose,
  requestAuthorisation,
}: WorkflowFormProps): JSX.Element | null => {
  const queryClient = useQueryClient();

  const refetchData = useCallback(async () => {
    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.includes('workflows'),
    });
  }, [queryClient]);

  switch (mode) {
    case ResourceActions.CREATE:
      return <CreateWorkflowForm onClose={onClose} />;
    case ResourceActions.DUPLICATE:
      if (workflowID === undefined) {
        return null;
      }

      return (
        <DuplicateWorkflowForm
          workflowID={workflowID}
          refetchData={refetchData}
          onClose={onClose}
        />
      );
    case ResourceActions.RENAME:
      if (workflowID === undefined) {
        return null;
      }

      return (
        <RenameWorkflowForm
          workflowID={workflowID}
          refetchData={refetchData}
          onClose={onClose}
          requestAuthorisation={requestAuthorisation}
        />
      );
    case ResourceActions.DELETE:
      if (workflowID === undefined) {
        return null;
      }

      return (
        <DeleteWorkflowForm
          workflowID={workflowID}
          refetchData={refetchData}
          onClose={onClose}
          requestAuthorisation={requestAuthorisation}
        />
      );
    default:
      return null;
  }
};
