import { useQueryClient } from '@tanstack/react-query';
import Stack from '@mui/material/Stack';
import { TrackedButton } from '@automata/ui';
import { logger, getErrorMessage } from '@automata/utils';
import { Group as CreateGroupProps } from '@automata/api/apiSchemas';
import {
  CreateDeviceGroupV2Error,
  DeleteDeviceGroupV2Error,
  UpdateDeviceGroupV2Error,
  useCreateDeviceGroupV2,
  useDeleteDeviceGroupV2,
  useUpdateDeviceGroupV2,
} from '@automata/api/apiComponents';
import { ResourceActions } from 'components/ResourceActions';
import { CreateEdit } from 'components/GroupForm/CreateEdit';
import { RoutedGroup } from 'screens/DevicesScreen/DevicesList/types';
import { useSnackbar } from 'hooks/useSnackbar';
import { useWorkspaceID } from 'hooks/useWorkspaceID';
import { NIL as NIL_UUID } from 'uuid';

interface EditGroupProps {
  group?: RoutedGroup;
  mode: ResourceActions;
  onClose: () => void;
}

interface Props {
  group: EditGroupProps['group'];
  mode: ResourceActions;
  onClose: () => void;
}

type FormValues = CreateGroupProps;

type CrudError =
  | CreateDeviceGroupV2Error
  | DeleteDeviceGroupV2Error
  | UpdateDeviceGroupV2Error;

enum Operation {
  CREATE = 'creating',
  UPDATE = 'updating',
  DELETE = 'deleting',
}

const groupSelectionError = (
  <h3>An error occurred while selecting the group. Please reload the page.</h3>
);

export const GroupForm = ({ group, mode, onClose }: Props): JSX.Element => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const workspaceID = useWorkspaceID();

  const { mutate: createGroup } = useCreateDeviceGroupV2();
  const { mutate: updateGroup } = useUpdateDeviceGroupV2();
  const { mutate: deleteGroup } = useDeleteDeviceGroupV2();

  const handleSuccess = async () => {
    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.includes('device-groups'),
    });
    onClose();
  };

  const handleError = (error: CrudError, operation: Operation) => {
    const message = getErrorMessage(
      error,
      `unknown error while ${operation} the group`
    );
    logger.error(message);
    enqueueSnackbar(`An error occurred while ${operation} the group.`, {
      variant: 'error',
    });
    onClose();
  };

  const handleCreateGroup = (values: FormValues) => {
    if (!workspaceID) return;
    const parent_id = values.parent_id === NIL_UUID ? null : values.parent_id;

    createGroup(
      { body: { ...values, parent_id }, pathParams: { workspaceID } },
      {
        onSuccess: handleSuccess,
        onError: (error) => {
          handleError(error, Operation.CREATE);
        },
      }
    );
  };

  const handleUpdateGroup = (values: FormValues) => {
    if (!group) throw new Error('Cannot update group.');

    const parent_id = values.parent_id === NIL_UUID ? null : values.parent_id;

    updateGroup(
      { body: { ...values, parent_id }, pathParams: { groupID: group.id } },
      {
        onSuccess: handleSuccess,
        onError: (error) => {
          handleError(error, Operation.UPDATE);
        },
      }
    );
  };

  const handleDeleteGroup = () => {
    if (!group) throw new Error('Cannot delete group.');

    deleteGroup(
      { pathParams: { groupID: group.id } },
      {
        onSuccess: handleSuccess,
        onError: (error) => {
          handleError(error, Operation.DELETE);
        },
      }
    );
  };

  switch (mode) {
    case ResourceActions.CREATE:
      return (
        <CreateEdit handleClose={onClose} handleConfirm={handleCreateGroup} />
      );
    case ResourceActions.EDIT:
      if (!group) return groupSelectionError;

      return (
        <CreateEdit
          handleClose={onClose}
          handleConfirm={handleUpdateGroup}
          group={group}
        />
      );
    case ResourceActions.DELETE:
      if (!group) return groupSelectionError;

      return (
        <Stack direction="row" justifyContent="flex-end" gap={2}>
          <TrackedButton
            type="reset"
            trackLabel="Group-Delete-cancel"
            variant="outlined"
            onClick={onClose}
          >
            Cancel
          </TrackedButton>
          <TrackedButton
            type="submit"
            trackLabel="Group-Delete-submit"
            variant="contained"
            onClick={handleDeleteGroup}
          >
            Delete
          </TrackedButton>
        </Stack>
      );
    default:
      throw new Error('Invalid mode');
  }
};
