import Alert from '@mui/material/Alert';
import { getErrorMessage } from '@automata/utils';

export interface AsyncStatusProps {
  error?: unknown;
  success?: boolean;
  successMessage?: string;
  loading?: boolean;
  loadingMessage?: string;
}

export const AsyncStatus = ({
  error,
  success,
  successMessage,
  loading,
  loadingMessage,
}: AsyncStatusProps): JSX.Element => {
  const errorMsg = getErrorMessage(error, 'An error occurred');

  return (
    <>
      {error !== undefined && <Alert severity="error">{errorMsg}</Alert>}
      {success && (
        <Alert severity="success">{successMessage ?? 'Success!'}</Alert>
      )}
      {loading && (
        <Alert severity="info">{loadingMessage ?? 'Loading...'}</Alert>
      )}
    </>
  );
};
