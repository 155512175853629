import { useCallback } from 'react';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/system/Stack';
import { useController, useForm } from 'react-hook-form';
import { WorkflowCreatePayload, WorkflowType } from '@automata/api/apiSchemas';
import { schemas } from '@automata/api/zod';
import { useCreateWorkflow } from '@automata/api/apiComponents';
import { useSnackbar } from 'hooks/useSnackbar';
import { AsyncStatus } from 'components/AsyncStatus';
import { zodResolver } from '@hookform/resolvers/zod';
import { Select, TextField, TrackedButton } from '@automata/ui';
import { behaviours, getWorkflowBehaviour } from 'model/WorkflowTypes';
import { capitaliseFirst } from 'utils/helpers';
import { useRouter } from 'next/router';
import { GlobalRoutes } from 'GlobalRoutes';
import { useRootUrl } from 'hooks/useRootUrl';
import { getErrorMessage } from '@automata/utils';
import { useWorkspaceID } from 'hooks/useWorkspaceID';

const defaultValues: WorkflowCreatePayload = {
  name: '',
  type: 'python',
};

interface Props {
  onClose: () => void;
}

export const CreateWorkflowForm = ({ onClose }: Props): JSX.Element => {
  const router = useRouter();
  const { workspaceID } = useRootUrl();
  const { register, control, handleSubmit, formState } =
    useForm<WorkflowCreatePayload>({
      defaultValues: defaultValues,
      resolver: zodResolver(schemas.WorkflowCreatePayload),
    });
  const { field: typeField } = useController({
    name: 'type',
    control,
  });
  const {
    mutateAsync: createWorkflow,
    isLoading: isUpdating,
    error,
  } = useCreateWorkflow();

  const { enqueueSnackbar } = useSnackbar();

  const currentWorkspaceID = useWorkspaceID();

  const onSubmit = useCallback(
    async (formData: WorkflowCreatePayload) => {
      if (currentWorkspaceID === undefined || workspaceID === undefined) {
        return;
      }

      try {
        const workflow = await createWorkflow({
          body: formData,
          pathParams: {
            workspaceID: currentWorkspaceID,
          },
        });
        enqueueSnackbar('Workflow created', { variant: 'success' });
        onClose();
        router.push(
          `${GlobalRoutes.workflow.replace(':org', workspaceID)}/${
            workflow.id
          }?editable=true`
        );
      } catch (err: unknown) {
        const error = getErrorMessage(err, 'unknown error');
        enqueueSnackbar(`Cannot create workflow: ${error}`, {
          variant: 'error',
        });
      }
    },
    [
      currentWorkspaceID,
      workspaceID,
      createWorkflow,
      enqueueSnackbar,
      onClose,
      router,
    ]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <Stack spacing={2}>
          <TextField
            margin="dense"
            label="Workflow Name"
            fullWidth
            {...register('name')}
            data-cy="wflow-form-name"
          />
          <FormControl fullWidth>
            <Select
              labelId="select-label-workflow"
              label="Select workflow type"
              data-testid="select-workflow-type"
              {...typeField}
              fullWidth
            >
              {Object.keys(behaviours)
                .filter(
                  (key: string) => behaviours[key as WorkflowType].enabled
                )
                .map((workflowType) => (
                  <MenuItem key={workflowType} value={workflowType}>
                    {`${capitaliseFirst(
                      getWorkflowBehaviour(workflowType as WorkflowType).label
                    )} Editor`}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Stack>
        {error !== null && <AsyncStatus error={error as any} />}
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <TrackedButton
            trackLabel="form-cancel-create"
            disabled={isUpdating}
            variant="outlined"
            onClick={onClose}
            size="small"
          >
            Cancel
          </TrackedButton>
          <TrackedButton
            trackLabel="form-submit-create"
            loading={isUpdating}
            disabled={!formState.isValid}
            variant="contained"
            type="submit"
            size="small"
          >
            Create
          </TrackedButton>
        </Stack>
      </Stack>
    </form>
  );
};
