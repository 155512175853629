import {
  ComplianceConfig,
  ComplianceDeviceStateConfig,
  ComplianceResourceConfig,
} from '@automata/api/apiSchemas';
import useUserV2 from './useUserV2';

const complianceResourceConfigEntries: Record<
  keyof ComplianceResourceConfig,
  null
> = {
  create: null,
  delete: null,
  duplicate: null,
  edit: null,
  rename: null,
};

const complianceDeviceStateConfigEntries: Record<
  keyof ComplianceDeviceStateConfig,
  null
> = {
  configure: null,
  load: null,
  pause: null,
  reset: null,
  resume: null,
  start: null,
  stop: null,
};

const complianceResourceConfigKeys = Object.keys(
  complianceResourceConfigEntries
);

const complianceDeviceStateConfigKeys = Object.keys(
  complianceDeviceStateConfigEntries
);

const isComplianceResourceConfig = (
  x: unknown
): x is ComplianceResourceConfig => {
  const y = x as ComplianceResourceConfig;
  return (
    y &&
    (
      complianceResourceConfigKeys as Array<keyof ComplianceResourceConfig>
    ).every((key) => typeof y[key] === 'boolean')
  );
};

const isKeyOfComplianceResourceConfig = (
  key: string
): key is keyof ComplianceResourceConfig => {
  return complianceResourceConfigKeys.includes(key);
};

const isKeyOfComplianceDeviceStateConfig = (
  key: string
): key is keyof ComplianceDeviceStateConfig => {
  return complianceDeviceStateConfigKeys.includes(key);
};

export interface UseCompliance {
  requiresSigning(
    resourceType: keyof ComplianceConfig,
    resourceAction:
      | keyof ComplianceResourceConfig
      | keyof ComplianceDeviceStateConfig
  ): boolean;
}

export const useCompliance = (): UseCompliance => {
  const { data: user } = useUserV2();

  const requiresSigning = (
    resourceType: keyof ComplianceConfig,
    resourceAction:
      | keyof ComplianceResourceConfig
      | keyof ComplianceDeviceStateConfig
  ): boolean => {
    const compliance = user?.currentOrganization?.configuration.compliance;
    const resource = compliance?.[resourceType];

    if (resource && isComplianceResourceConfig(resource)) {
      return (
        isKeyOfComplianceResourceConfig(resourceAction) &&
        resource[resourceAction]
      );
    }

    return (
      isKeyOfComplianceDeviceStateConfig(resourceAction) &&
      (resource?.[resourceAction] ?? false)
    );
  };

  return {
    requiresSigning,
  };
};
