import { initialGitWorkflow } from 'components/GitEditor/defs/initialState';
import { initialMaestroWorkflow } from 'components/MaestroEditor/defs/initialState';
import {
  WorkflowDefinition,
  WorkflowType,
  Workflow,
  WorkflowDetailed,
  WorkflowAttributes,
} from '@automata/api/apiSchemas';
import { TextEditor } from 'components/TextEditor';
import { GitEditor } from 'components/GitEditor';
import { MaestroEditor } from 'components/MaestroEditor';

export interface EditorProps {
  name: string;
  updateData: (
    definition: WorkflowDefinition,
    attributes?: WorkflowAttributes
  ) => Promise<WorkflowDetailed | undefined>;
  requestData: WorkflowDetailed | undefined;
  onContentUpdated: (a: Workflow | null | undefined) => void;
  workflow?: WorkflowDetailed;
}

export type WorkflowBehaviour = {
  label: string;
  canDeployDirectly: boolean;
  initialDefinition: WorkflowDefinition;
  Editor: React.FunctionComponent<EditorProps>;
  enabled?: boolean;
};

export const behaviours: Record<WorkflowType, WorkflowBehaviour> = {
  python: {
    canDeployDirectly: true,
    label: 'script',
    initialDefinition: {
      python: '',
    },
    Editor: TextEditor,
    enabled: true,
  },
  git: {
    canDeployDirectly: true,
    label: 'git',
    initialDefinition: {
      git: initialGitWorkflow,
    },
    Editor: GitEditor,
    enabled: true,
  },
  maestro: {
    canDeployDirectly: true,
    label: 'maestro',
    initialDefinition: {
      maestro: initialMaestroWorkflow,
    },
    Editor: MaestroEditor,
    enabled: true,
  },
};

export const getWorkflowBehaviour = (type: WorkflowType): WorkflowBehaviour => {
  return behaviours[type];
};
