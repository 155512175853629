/**
 * Use a placeholder scheme and domain along with URL to safely parse
 * and manipulate the path/query parameters.
 *
 * The url is then convered back to a string and the placeholder removed.
 *
 * This allows the use of very mature code to parse the url.
 */
export const mutatePathAsUrl = (
  path: string,
  mutate: (url: URL) => URL
): string => {
  const host = 'http://e.com';
  const url = new URL(`${host}${path}`);
  return mutate(url).toString().slice(host.length);
};

/**
 * Retrieve the first value if given a string, else return the current value.
 * @param value
 * @returns the first value from the array if value is an array, else the
 * string or undefined
 */
export const firstQueryParam = (
  value: string | string[] | undefined
): string | undefined => {
  switch (typeof value) {
    case 'string':
    case 'undefined':
      return value;
    default:
      return value[0];
  }
};

/**
 * Remove a list of query parameter names from a path
 * @param path path and query parameters of a url held within a string
 * @param keys query parameter key names to remove from path
 * @returns path without query parameters named within keys
 */
export const removeQueryParamsFromPath = (path: string, keys: string[]) => {
  return mutatePathAsUrl(path, (url) => {
    keys.forEach((key) => url.searchParams.delete(key));
    return url;
  });
};
