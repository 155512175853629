import { CodeEditorProps } from 'components/CodeEditor';
import dynamic from 'next/dynamic';
import { LegacyRef, forwardRef } from 'react';
import ReactAce from 'react-ace/lib/ace';

const CodeEditorInnerDynamicWrapper = dynamic(
  async () => {
    const mod = await import('components/CodeEditor');
    await import('ace-builds');
    return mod.CodeEditorInner;
  },
  {
    ssr: false,
  }
);

export const CodeEditor = forwardRef(
  (props: CodeEditorProps, ref: LegacyRef<ReactAce> | undefined) => (
    <CodeEditorInnerDynamicWrapper {...props} forwardRef={ref} />
  )
);

CodeEditor.displayName = 'CodeEditor';
