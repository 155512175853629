import { useCallback } from 'react';
import Stack from '@mui/system/Stack';
import { useForm } from 'react-hook-form';
import {
  DuplicateResourcePayload,
  WorkflowDetailed,
} from '@automata/api/apiSchemas';
import { schemas } from '@automata/api/zod';
import {
  useDuplicateWorkflow,
  useGetWorkflowDetails,
} from '@automata/api/apiComponents';
import { useSnackbar } from 'hooks/useSnackbar';
import { Placeholder } from 'components/Placeholder';
import { AsyncStatus } from 'components/AsyncStatus';
import { TextField, TrackedButton } from '@automata/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { GlobalRoutes } from 'GlobalRoutes';
import { useRouter } from 'next/router';
import { useRootUrl } from 'hooks/useRootUrl';
import { getErrorMessage } from '@automata/utils';

interface FormWrapperProps {
  workflow: WorkflowDetailed;
  onClose: () => void;
  refetchData: () => Promise<void>;
}

const FormWrapper = ({
  workflow,
  refetchData,
  onClose,
}: FormWrapperProps): JSX.Element => {
  const { error, isLoading, mutateAsync } = useDuplicateWorkflow({
    onSuccess: async () => {
      refetchData();
    },
  });

  const router = useRouter();
  const { workspaceID } = useRootUrl();

  const { enqueueSnackbar } = useSnackbar();

  const defaultValues: DuplicateResourcePayload = {
    name: `${workflow.name}-copy`,
  };

  const { handleSubmit, register, formState } =
    useForm<DuplicateResourcePayload>({
      defaultValues: defaultValues,
      resolver: zodResolver(schemas.DuplicateResourcePayload),
    });

  const onSubmit = useCallback(
    async (formData: DuplicateResourcePayload) => {
      if (workspaceID === undefined) {
        return;
      }
      try {
        const newWorkflow = await mutateAsync({
          body: formData,
          pathParams: { workflowID: workflow.id },
        });
        enqueueSnackbar('Workflow duplicated', { variant: 'success' });
        onClose();
        router.push(
          `${GlobalRoutes.workflow.replace(':org', workspaceID)}/${
            newWorkflow.id
          }`
        );
      } catch (err: unknown) {
        const error = getErrorMessage(err, 'unknown error');
        enqueueSnackbar(`Cannot duplicate workflow: ${error}`, {
          variant: 'error',
        });
      }
    },
    [workspaceID, mutateAsync, workflow.id, enqueueSnackbar, onClose, router]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <TextField
          margin="dense"
          label="Workflow Name"
          fullWidth
          {...register('name')}
          data-cy="wflow-form-name"
        />
        {error && <AsyncStatus error={error} />}
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <TrackedButton
            trackLabel="form-cancel-duplicate"
            disabled={isLoading}
            color="secondary"
            variant="text"
            onClick={onClose}
          >
            Cancel
          </TrackedButton>
          <TrackedButton
            trackLabel="form-submit-duplicate"
            loading={isLoading}
            disabled={!formState.isValid}
            variant="contained"
            type="submit"
          >
            Duplicate
          </TrackedButton>
        </Stack>
      </Stack>
    </form>
  );
};

interface Props {
  onClose: () => void;
  refetchData: () => Promise<void>;
  workflowID: string;
}

export const DuplicateWorkflowForm = ({
  workflowID,
  refetchData,
  onClose,
}: Props): JSX.Element | null => {
  const {
    data: workflowData,
    isLoading,
    isLoadingError,
  } = useGetWorkflowDetails({
    pathParams: {
      workflowID: workflowID,
    },
  });

  if (isLoading === true) {
    return <Placeholder copy="Getting data..." />;
  }

  if (workflowData === undefined && isLoadingError === true) {
    return <Placeholder copy="Error loading workflow" />;
  }

  return (
    <FormWrapper
      workflow={workflowData}
      refetchData={refetchData}
      onClose={onClose}
    />
  );
};
