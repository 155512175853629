import { Select } from '@automata/ui';
import MenuItem from '@mui/material/MenuItem';
import { ControllerRenderProps } from 'react-hook-form';
import { EvalVersions } from '@automata/api/apiSchemas';
import { MaestroEditorContent } from 'components/MaestroEditor';

interface EvalsSelectorProps {
  field: ControllerRenderProps<
    MaestroEditorContent,
    `workflow.resource_versions.evals_version`
  >;
  evalVersionsData: EvalVersions;
  disabled?: boolean;
}

export const EvalsSelector = ({
  field,
  evalVersionsData,
  disabled = false,
}: EvalsSelectorProps): JSX.Element | null => {
  if (evalVersionsData.versions.length === 0) {
    return null;
  }

  return (
    <Select
      {...field}
      disabled={disabled}
      label="Evals Version"
      MenuProps={{ sx: { height: '50vh' } }}
      error={!field.value}
      helperText={field.value ? '' : 'Required'}
    >
      {evalVersionsData.versions.map((version) => (
        <MenuItem key={version.name} value={version.name}>
          {version.name}
        </MenuItem>
      ))}
    </Select>
  );
};
