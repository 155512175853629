import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Select, TextField, TrackedButton } from '@automata/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import { useGetWorkspaceDeviceGroupsV2 } from '@automata/api/apiComponents';
import { Group as GroupFormValues } from '@automata/api/apiSchemas';
import { schemas } from '@automata/api/zod';
import { useWorkspaceID } from 'hooks/useWorkspaceID';
import { buildGroupsTree, generateTreeMenuItems } from './utils';
import { NIL as NIL_UUID } from 'uuid';
import { RoutedGroup } from 'screens/DevicesScreen/DevicesList/types';
import { useSnackbar } from 'hooks/useSnackbar';
import { logger, getErrorMessage } from '@automata/utils';

export interface Props {
  handleClose: () => void;
  handleConfirm: (values: GroupFormValues) => void;
  group?: RoutedGroup;
}

export const CreateEdit = ({
  group,
  handleClose,
  handleConfirm,
}: Props): JSX.Element | null => {
  const workspaceID = useWorkspaceID();
  const { data: groupsData, error } = useGetWorkspaceDeviceGroupsV2(
    {
      pathParams: { workspaceID: workspaceID || '' },
    },
    { enabled: !!workspaceID }
  );
  const { enqueueSnackbar } = useSnackbar();

  const initialValues: GroupFormValues = {
    parent_id: group?.parent_id ?? NIL_UUID,
    name: group?.name ?? '',
    members: group?.members ?? [],
  };

  const {
    handleSubmit,
    register,
    watch,
    formState: { isValid },
  } = useForm<GroupFormValues>({
    defaultValues: initialValues,
    resolver: zodResolver(schemas.Group),
  });

  const groupsTree = useMemo(
    () => buildGroupsTree(groupsData?.groups || []),
    [groupsData?.groups]
  );

  const treeMenuItems = useMemo(
    () => groupsTree.map((group) => generateTreeMenuItems(group, 0)),
    [groupsTree]
  );

  if (error) {
    handleClose();
    enqueueSnackbar(
      'An error occurred while fetching groups data. Please retry.',
      {
        variant: 'error',
      }
    );
    const errorMessage = getErrorMessage(
      error,
      'Failed to fetch workspace hub groups'
    );
    logger.error(errorMessage);
    return null;
  }

  return (
    <form onSubmit={handleSubmit(handleConfirm)}>
      <Stack gap={1}>
        <TextField label="Group Name" {...register('name')} />
        <Select
          fullWidth
          label="Parent"
          placeholder="Select a parent group"
          value={watch('parent_id')}
          {...register('parent_id')}
        >
          <MenuItem value={NIL_UUID}>NO PARENT</MenuItem>
          {treeMenuItems}
        </Select>
        <Stack direction="row" justifyContent="flex-end" gap={2}>
          <TrackedButton
            type="reset"
            trackLabel="Group.CreateEdit.cancel"
            variant="outlined"
            onClick={handleClose}
          >
            Cancel
          </TrackedButton>
          <TrackedButton
            type="submit"
            trackLabel="Group.CreateEdit.submit"
            variant="contained"
            disabled={!isValid}
          >
            {group ? 'Save changes' : 'Create Group'}
          </TrackedButton>
        </Stack>
      </Stack>
    </form>
  );
};
