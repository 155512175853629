import { WorkflowMaestro } from '@automata/api/apiSchemas';

const transportJson = {
  device_configs: {},
  transport_links: {},
};

const workflowJson = {};

export const initialMaestroWorkflow: WorkflowMaestro = {
  resource_versions: {},
  simulateDrivers: false,
  simulateTime: false,
  transport: JSON.stringify(transportJson),
  workflow: JSON.stringify(workflowJson),
};
