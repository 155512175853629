import { env } from '@automata/utils';
import { MaestroVersion } from '@automata/api/apiSchemas';
import { EnvEnum } from 'utils/envEnum';

export const getVersionCompatibleName = (version: MaestroVersion): string =>
  `${version.name}${!version.compatible ? ' (incompatible)' : ''}`;

export const checkAllowCustomVersions = (): boolean => {
  const environment = env('NEXT_PUBLIC_ENV');
  return environment === EnvEnum.LOCAL || environment === EnvEnum.DEV;
};
