import { AllDriversLatestVersion, AllDriversPackage } from 'model/Archetypes';
import { useGetArchetypeVersions } from '@automata/api/apiComponents';

export interface useArchetypesVersionsResult {
  loading: boolean;
  result: string;
  all: string[];
}

export const useArchetypesVersions = (): useArchetypesVersionsResult => {
  const { data, isLoading } = useGetArchetypeVersions({});

  return {
    result:
      data?.packages?.[AllDriversPackage]?.versions[0] ??
      AllDriversLatestVersion,
    all: data?.packages?.[AllDriversPackage]?.versions ?? [
      AllDriversLatestVersion,
    ],
    loading: isLoading,
  };
};
