export const checkSemverVersionCompatibility = (
  version: string,
  compatibleVersion: string
): boolean => {
  // Extract version components
  const versionComponents = version.replace(/^v/, '').split('.').map(Number);
  const compatibleComponents = compatibleVersion
    .replace(/^v/, '')
    .split('.')
    .map((component) => {
      return component === '*' ? -1 : parseInt(component); // Treat '*' as -1
    });

  // Ensure both versions have valid components
  if (versionComponents.some(isNaN) || compatibleComponents.some(isNaN)) {
    // Some error occured, just mark invalid
    return false;
  }

  // Ensure both versions have the same length
  while (versionComponents.length < compatibleComponents.length) {
    versionComponents.push(0); // Pad versionComponents with 0s
  }

  // Compare major versions
  if (
    compatibleComponents[0] !== -1 &&
    versionComponents[0] !== compatibleComponents[0]
  ) {
    // Major versions must match - indicates breaking changes
    return false;
  }

  // Compare versions
  for (let i = 0; i < compatibleComponents.length; i++) {
    const v = versionComponents[i];
    const c = compatibleComponents[i];

    if (c === -1 || v > c) {
      // If compatible component is '*', or version component is greater
      return true;
    } else if (v < c) {
      return false;
    }
    // If versions are equal, continue to the next component
  }

  // If all components are equal, versions are equal or compatible
  return true;
};
