import { FlatGroup } from '@automata/api/apiSchemas';
import { GroupTree } from './types';
import MenuItem from '@mui/material/MenuItem';

/**
 * Creates a tree of groups from a flat list of groups.
 * @param items
 * @returns an object representing the root nodes of the tree
 */
export function buildGroupsTree(items: FlatGroup[]): GroupTree[] {
  const groupsMap = new Map<string, GroupTree>();

  // create a map of nodes keyed by their ID
  items.forEach((item) => {
    groupsMap.set(item.id, { ...item, children: [] });
  });

  // add child nodes to their parent's children array
  items.forEach((item) => {
    const node = groupsMap.get(item.id);

    if (node?.parent_id) {
      const parent = groupsMap.get(node.parent_id);
      if (parent) {
        parent.children.push(node);
      }
    }
  });

  // return the root nodes (i.e., nodes with no parent) in alphabetical order
  return Array.from(groupsMap.values())
    .filter((node) => !node.parent_id)
    .sort((a, b) => a.name.localeCompare(b.name));
}

export const INDENT_SIZE = 4;

export const generateTreeMenuItems = (
  group: GroupTree,
  level: number
): JSX.Element[] => {
  const items: JSX.Element[] = [];

  items.push(
    <MenuItem key={group.id} value={group.id} sx={{ ml: level * INDENT_SIZE }}>
      {group.name}
    </MenuItem>
  );

  group.children.forEach((child) => {
    items.push(...generateTreeMenuItems(child, level + 1));
  });

  return items;
};
