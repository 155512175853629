import { useEffect, useState } from 'react';
import useUserV2 from './useUserV2';

interface UseRootUrl {
  workspaceID: string | undefined;
  baseRouteWithOrg: string;
  isLoading: boolean;
  error: unknown[] | null;
}

export const useRootUrl = (destinationRoute?: string): UseRootUrl => {
  const [workspaceID, setworkspaceID] = useState<undefined | string>();
  const [baseRouteWithOrg, setbaseRouteWithOrg] = useState<string>('/');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const {
    data: user,
    isLoading: isUserLoading,
    error: userError,
  } = useUserV2();

  useEffect(() => {
    if (isUserLoading || userError) return;

    if (user.currentWorkspace.id) {
      setworkspaceID(user.currentWorkspace.id);
      if (destinationRoute) {
        setbaseRouteWithOrg(
          destinationRoute?.replace(':org', user.currentWorkspace.id)
        );
      }
      setIsLoading(false);
    }
  }, [destinationRoute, isUserLoading, user?.currentWorkspace.id, userError]);

  return {
    workspaceID,
    baseRouteWithOrg,
    isLoading,
    error: userError,
  };
};
