import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Popover from '@mui/material/Popover';
import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { Typography } from '@automata/ui';

export interface Props {
  buttonId?: string;
  copy: string;
  width?: string | number;
}

export const InfoTooltip = ({
  buttonId,
  copy,
  width = '328px',
}: Props): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton
        id={buttonId}
        data-testid="infoTooltip-icon"
        onClick={handleClick}
        sx={{ p: 0 }}
        aria-label="info-tooltip"
        disableRipple
      >
        <InfoOutlined fontSize="small" />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        data-testid="infoTooltip-popover"
        PaperProps={{ sx: { width, p: 2 } }}
      >
        <Typography variant="caption">{copy}</Typography>
      </Popover>
    </>
  );
};
