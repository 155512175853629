import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

interface Props {
  size?: number;
}

const styles = {
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export const Spinner = ({ size }: Props): JSX.Element => {
  return (
    <Box sx={styles.container}>
      <CircularProgress
        size={size ?? 18}
        thickness={6}
        aria-label="circular progress"
      />
    </Box>
  );
};
