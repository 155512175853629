import { useCallback } from 'react';
import Stack from '@mui/system/Stack';
import { useForm } from 'react-hook-form';
import { Reason } from '@automata/api/apiSchemas';
import { schemas } from '@automata/api/zod';
import { useDeleteWorkflow } from '@automata/api/apiComponents';
import { useSnackbar } from 'hooks/useSnackbar';
import { useFlag } from 'hooks/unleashHooks';
import { WarningCard } from 'components/WarningCard';
import { AsyncStatus } from 'components/AsyncStatus';
import { TextField, TrackedButton, Typography } from '@automata/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { useRouter } from 'next/router';
import { GlobalRoutes } from 'GlobalRoutes';
import { useRootUrl } from 'hooks/useRootUrl';
import { useQueryClient } from '@tanstack/react-query';
import { env, getErrorMessage } from '@automata/utils';

interface Props {
  onClose: () => void;
  refetchData: () => Promise<void>;
  workflowID: string;
  requestAuthorisation?: string;
}

export const DeleteWorkflowForm = ({
  workflowID,
  onClose,
  requestAuthorisation,
}: Props): JSX.Element | null => {
  const { query, push } = useRouter();
  const { workspaceID } = useRootUrl();
  const isReasonEnabled = useFlag('fe.reason');
  const queryClient = useQueryClient();

  const {
    mutateAsync: deleteWorkflow,
    isLoading: isDeleting,
    error,
  } = useDeleteWorkflow();

  const { enqueueSnackbar } = useSnackbar();

  const defaultValues: Reason = {
    reason: {
      details: isReasonEnabled ? '' : env('NEXT_PUBLIC_REASON_DEFAULT_STRING'),
    },
  };

  const { handleSubmit, register, formState } = useForm<Reason>({
    defaultValues: defaultValues,
    resolver: zodResolver(schemas.Reason),
  });

  const reasonIsEnabled = useFlag('fe.reason');

  const onSubmit = useCallback(
    async (formData: Reason) => {
      try {
        await deleteWorkflow({
          body: formData,
          pathParams: { workflowID: workflowID },
          headers: {
            'X-Request-Authorization': requestAuthorisation,
          },
        });
        enqueueSnackbar('Workflow deleted', { variant: 'success' });
        onClose();
        const isDashboardPage = typeof query.id === 'string';
        if (isDashboardPage && workspaceID !== undefined) {
          push(GlobalRoutes.workflow.replace(':org', workspaceID));
        } else {
          await queryClient.invalidateQueries({
            predicate: (query) => query.queryKey.includes('workflows'),
          });
        }
      } catch (err: unknown) {
        const error = getErrorMessage(err, 'unknown error');
        enqueueSnackbar(`Cannot delete workflow: ${error}`, {
          variant: 'error',
        });
      }
    },
    [
      deleteWorkflow,
      enqueueSnackbar,
      onClose,
      push,
      query.id,
      queryClient,
      requestAuthorisation,
      workspaceID,
      workflowID,
    ]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <Stack spacing={2}>
          {reasonIsEnabled && (
            <>
              <Typography>You need to add a reason for this change</Typography>
              <TextField
                margin="dense"
                multiline
                placeholder="Reason"
                fullWidth
                {...register('reason.details')}
              />
            </>
          )}
          <WarningCard message="Please note this action cannot be undone" />
        </Stack>
        {error !== null && (
          <AsyncStatus
            error={getErrorMessage(error, 'Error deleting workflow')}
          />
        )}
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <TrackedButton
            trackLabel="form-cancel-delete"
            disabled={isDeleting}
            color="secondary"
            variant="text"
            onClick={onClose}
          >
            Cancel
          </TrackedButton>
          <TrackedButton
            trackLabel="form-submit-delete"
            loading={isDeleting}
            disabled={!formState.isValid}
            variant="contained"
            type="submit"
          >
            Delete
          </TrackedButton>
        </Stack>
      </Stack>
    </form>
  );
};
